import { Injectable } from '@angular/core';
import { ISignupUserDetails } from './ISignupUserDetails';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';

@Injectable()
export class SignupServiceComponent {

  // tslint:disable-next-line: deprecation
  constructor(private http: Http) { }

  authenticateSignupUser(playerid: string, dateofbirth: string, zip: string): Promise<ISignupUserDetails> {
      console.log(environment.apiEndpoint + '/Dashboard/AuthenticateSignupUser/' + playerid + '/' + dateofbirth + '/' + zip);

      return this.http.get(environment.apiEndpoint + '/Dashboard/AuthenticateSignupUser/' + playerid + '/' + dateofbirth + '/' + zip)
            .toPromise()
            .then(res => res.json())
            .catch(err => {
                return Promise.reject(err.json().error  || 'Server error');
            });

  }
  
}
