import { Component, OnInit } from '@angular/core';
import { IOfferDetails } from './IOfferDetails';
import { IOfferResponse } from './IOfferReponse';
import { OfferPromoServiceComponent } from './offerpromo-service';
import { DatePipe } from '@angular/common';
import { element } from 'protractor';
import { all } from 'q';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';

@Component({
  selector: 'app-offerpromo',
  templateUrl: './offerpromo.component.html',
  styleUrls: ['./offerpromo.component.css'],
  providers: [OfferPromoServiceComponent]
})
export class OfferpromoComponent implements OnInit {
  OfferTypes: string[];
  Offers: IOfferDetails[];
  OfferStartDate: string;
  OfferEndDate: string;
  selectedChkValue = [];
  UserName: string;
  ObjResponse: IOfferResponse;
  IsResetFilter = false;
  IsValid = true;

  constructor(private appService: OfferPromoServiceComponent, private datePipe: DatePipe, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.UserName = sessionStorage.getItem('AppUserName');

    this.appService.getOfferTypes()
    .then(svcData => {
      this.OfferTypes = svcData;

      this.OfferTypes.forEach((item, index) => {
        this.selectedChkValue.push(item);
      });

      let selectedCheckedValues: string = this.selectedChkValue.join(',');

      //$('.mat-input-element').prop('value', '1/1/2016 - ' + this.datePipe.transform(new Date(),"MM/dd/yyyy"));
      let currentDate = this.datePipe.transform(new Date(), "MM/dd/yyyy");
      let dateEnd = new Date(); dateEnd.setDate(dateEnd.getDate() + 45);
      let endRange = this.datePipe.transform(dateEnd, "MM/dd/yyyy");

      $('.mat-input-element').prop('value', currentDate + '- ' + endRange);


      this.OfferStartDate = this.datePipe.transform(currentDate, "MM-dd-yyyy");
      this.OfferEndDate = this.datePipe.transform(endRange, "MM-dd-yyyy"); 

      this.appService.getOfferData(this.OfferStartDate, this.OfferEndDate, selectedCheckedValues)
      .then(offData => {
        this.ObjResponse = offData;

        if (this.ObjResponse.Success === true) {
         this.Offers = this.ObjResponse.OfferList;
        } else {
         this.Offers.length = 0;
        }

      });
    });

    setTimeout(() => {
        /** spinner ends after 3 seconds */

        this.spinner.hide();
      }, 3000);
  }

  ShowOffers() {
    this.IsValid = true;
    this.IsResetFilter = false;
    let selectedCheckedValues: string = this.selectedChkValue.join(',');

    //console.log('selectedCheckedValues:' + selectedCheckedValues);
    //console.log('OfferStartDate:' + this.OfferStartDate);
    //console.log('OfferEndDate:' + this.OfferEndDate);

    if(selectedCheckedValues == '') {
      this.IsValid = false;
      this.Offers.length = 0;
    } 

    if(this.IsValid == true)
    {  
     this.appService.getOfferData(this.OfferStartDate, this.OfferEndDate, selectedCheckedValues)
      .then(svcData => {
         this.ObjResponse = svcData;

         if (this.ObjResponse.Success === true) {
          this.Offers = this.ObjResponse.OfferList;
         } else {
          this.Offers.length = 0;
         }

      });
    }
  }

  ResetFilter(): void {
    this.IsResetFilter = true;
    $(':checkbox').prop('checked', false);
    $('.mat-input-element').prop('value', '');
    this.Offers.length = 0;
    this.selectedChkValue.length = 0;
    //this.OfferStartDate = null;
    //this.OfferEndDate = null; 
    }



  GetDateRange(event: any) {
    this.OfferStartDate = this.datePipe.transform(new Date(event.target.value.begin),"MM-dd-yyyy");
    this.OfferEndDate = this.datePipe.transform(new Date(event.target.value.end),"MM-dd-yyyy");
  }
  chkChange(e, selOfferType) {
    if (e.target.checked) {
      if ((this.selectedChkValue.length > 0) && (this.selectedChkValue.indexOf(selOfferType) >= 0)) {
        this.selectedChkValue.splice(this.selectedChkValue.indexOf(selOfferType), 1);
      }

      this.selectedChkValue.push(selOfferType);
    }
    else {
     this.selectedChkValue.splice(this.selectedChkValue.indexOf(selOfferType), 1);
    }
  }
}
