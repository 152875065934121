import { Injectable } from '@angular/core';
import { CLoginRequestBody } from './CLoginRequestBody';
import { IAppUserDetails } from './IAppUserDetails';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';

@Injectable()
export class LoginServiceComponent {

  // tslint:disable-next-line: deprecation
  constructor(private http: Http) { }

  authenticateUser(userName: string, password: string): Promise<IAppUserDetails> {
      console.log(environment.apiEndpoint + '/Dashboard/AuthenticateUser/' + userName + '/' + password);
      //return this.http.get(environment.apiEndpoint + '/Dashboard/AuthenticateUser/' + userName + '/' + password)
      //.pipe(map((response: Response) => {
      //  console.log('In Service:' + new Date() + '-' + response.json());
      //  return response.json() as IAppUserDetails;
      //}));

      //return this.http.get(environment.apiEndpoint + '/Dashboard/AuthenticateUser/' + userName + '/' + encodeURIComponent(password) + '/')
      const RequestBody: CLoginRequestBody = new CLoginRequestBody(userName, password);
      return this.http.post(environment.apiEndpoint + '/Dashboard/AuthenticateUser', RequestBody)
            .toPromise()
            .then(res => res.json())
            .catch(err => {
                return Promise.reject(err.json().error  || 'Server error');
            });


  }

  public get currentUserValue(): string {
    //return sessionStorage.getItem('AppUserName');

    return null;
  }
}
