import { Injectable } from '@angular/core';
import { ISecurityQuestionDetails } from './ISecurityQuestionDetails';
import { CSaveSignupDetailsReqBody } from './CSaveSignupDetailsReqBody';
import { ISaveSignupDetailsResponse } from './ISaveSignupDetailsResponse';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';

@Injectable()
export class SignupDetailsServiceComponent {

  // tslint:disable-next-line: deprecation
  constructor(private http: Http, private httpClient: HttpClient) { }

  getSecurityQuestions(): Observable<ISecurityQuestionDetails[]> {
    return this.http.get(environment.apiEndpoint + '/Dashboard/GetSecurityQuestions')
    .pipe(map((response: Response) => response.json() as ISecurityQuestionDetails[]));
  }

  saveSignupAccountMaster(userId: string, password: string, securityQuestionId: number, answer: string): Promise<ISaveSignupDetailsResponse> {
  const targetId: number = Number(sessionStorage.getItem('TargetId'));
  const sendNotification: number = Number(sessionStorage.getItem('AppSignupSendNotification'));
  const RequestBody: CSaveSignupDetailsReqBody = new CSaveSignupDetailsReqBody(0, targetId, 0, userId, password, sendNotification, securityQuestionId, answer);

    return this.http.post(environment.apiEndpoint + '/Dashboard/SaveSignupAccountMaster', RequestBody)
    .toPromise()
    .then(res => res.json())
    .catch(err => {
     return Promise.reject(err.json().error  || 'Server error');
    });
  } 
}