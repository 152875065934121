import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignupDetailsServiceComponent } from './signupdetails.service';
import { ISecurityQuestionDetails } from './ISecurityQuestionDetails';
import { ISaveSignupDetailsResponse } from './ISaveSignupDetailsResponse';

@Component({
  selector: 'app-signupdetails',
  templateUrl: './signupdetails.component.html',
  styleUrls: ['./signupdetails.component.css'],
  providers: [SignupDetailsServiceComponent]
})

export class SignupdetailsComponent implements OnInit {
  danger = false;
  warning = false;
  success = false;
  signupdetailsForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;  
  signupdetailsError = '';
  SignupLoginName: string;
  SignupLoginPassword: string;
  SignupLoginConfirmPassword: string;
  SignupSecurityQuestion: string;
  SignupSecurityAnswer: string;
  SecurityQuestions: ISecurityQuestionDetails[];
  saveResponse: ISaveSignupDetailsResponse;
  sessionLoginUserId: string;
  sessionLoginPassword: string;
  loginUserId: string;
  loginPassword: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private appService: SignupDetailsServiceComponent
    ) {}

  ngOnInit() {

    this.signupdetailsForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', ''],
      confirmpassword: ['', ''],
      securityQuestion: ['', Validators.required],
      answer: ['', Validators.required]
    });

    // set return url to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'signupconfirm';

    //this.SignupLoginName = sessionStorage.getItem('AppSignupLoginName');
    //this.sessionLoginUserId = sessionStorage.getItem('AppSignupLoginName');

    //this.SignupLoginPassword = sessionStorage.getItem('AppSignupLoginPassword');
    //this.sessionLoginPassword = sessionStorage.getItem('AppSignupLoginPassword');

    if(sessionStorage.getItem('AppSignupLoginName') == null || sessionStorage.getItem('AppSignupLoginName') == '')
    {
      this.SignupLoginName = sessionStorage.getItem('PlayerId');
      this.sessionLoginUserId = sessionStorage.getItem('PlayerId');
    } else 
    {
      this.SignupLoginName = sessionStorage.getItem('AppSignupLoginName');
      this.sessionLoginUserId = sessionStorage.getItem('AppSignupLoginName');
    }

    this.SignupLoginPassword = sessionStorage.getItem('AppSignupLoginPassword');
    this.sessionLoginPassword = sessionStorage.getItem('AppSignupLoginPassword');

    //this.SignupLoginConfirmPassword = sessionStorage.getItem('AppSignupLoginPassword');
    this.SignupLoginConfirmPassword = '';

    this.SignupSecurityQuestion = sessionStorage.getItem('AppSignupSecurityQuestionId');
    this.SignupSecurityAnswer = sessionStorage.getItem('AppSignupSecurityAnswer');

    this.appService.getSecurityQuestions()
      .subscribe((svcData) => this.SecurityQuestions = svcData);

  }

  // convenience getter for easy access to form fields
  get f() { return this.signupdetailsForm.controls; }

  onSubmit() {
    this.submitted = true;
    $('.alert').removeAttr('style');
    this.danger = false;
    this.warning = false;
    this.success = false;
    this.loginUserId = '';
    this.loginPassword = '';


    if (this.f.username.value == null || this.f.username.value == '') {
      this.signupdetailsError = 'Please enter Rewards #.';
      this.danger = true;
      this.warning = true;
      this.success = false;
      console.log('Username field cannot be blank.');
      return;
    } 

    if(this.f.password.value == null || this.f.password.value == '')
    {
      this.signupdetailsError = 'Please enter Password.';
      this.danger = true;
      this.warning = true;
      this.success = false;
      console.log('Password cannot be blank.');
      return;
    } 

    console.log('confirmpassword:' + this.f.confirmpassword.value);
    if(this.f.confirmpassword.value != null && this.f.confirmpassword.value != '')
    {
      if(this.f.password.value != this.f.confirmpassword.value)
      { 
        this.signupdetailsError = 'Password and Confirm Password are mismatched.';
        this.danger = true;
        this.warning = true;
        this.success = false;
        console.log('Password and Confirm Password are mismatched.');
        return;
      }
    }

    console.log('sessionLoginUserId:' + this.sessionLoginUserId);
    console.log('username:' + this.f.username.value);
    console.log('sessionLoginPassword:' + this.sessionLoginPassword);
    console.log('password:' + this.f.password.value);
    if((this.sessionLoginUserId != this.f.username.value) || (this.sessionLoginPassword != this.f.password.value))
    {
      if(this.f.confirmpassword.value == null || this.f.confirmpassword.value == '')
      {
        this.signupdetailsError = 'Please enter Confirm Password.';
        this.danger = true;
        this.warning = true;
        this.success = false;
        console.log('Confirm Password cannot be blank.');
        return;
      } 
      this.loginUserId = this.f.username.value;
      this.loginPassword = this.f.password.value;
    } else {
      this.loginUserId = '';
      this.loginPassword = '';
    }
    
    if(this.f.securityQuestion.value == null || this.f.securityQuestion.value == '' || this.f.securityQuestion.value == '-1')
    {
      this.signupdetailsError = 'Please select Security Question.';
      this.danger = true;
      this.warning = true;
      this.success = false;
      console.log('Security Question is not selected.');
      return;
    }

    if(this.f.answer.value == null || this.f.answer.value == '')
    {
      this.signupdetailsError = 'Please enter Answer.';
      this.danger = true;
      this.warning = true;
      this.success = false;
      console.log('Answer cannot be blank.');
      return;
    }


    // stop here if form is invalid
    if (this.signupdetailsForm.invalid) {
      return;
    }

    console.log('Form is valid..');

    this.loading = true;

    this.appService.saveSignupAccountMaster(this.loginUserId, this.loginPassword, this.f.securityQuestion.value, this.f.answer.value)
      .then(svcData => {
        this.saveResponse = svcData;
        console.log('In Promise:' + this.saveResponse.Success);

        if (this.saveResponse.Success) {
          sessionStorage.setItem('AppSignupLoginName', this.f.username.value);
          sessionStorage.setItem('AppSignupLoginPassword', this.f.password.value);
          console.log('Signup details saved successfully.');

          this.router.navigate([this.returnUrl]);
        } else {
          this.signupdetailsError = 'Signup details could not be saved.';
          this.danger = true;
          this.warning = true;
          this.success = false;
          console.log('Failure Response:' + this.saveResponse.msg);
        }
      });

      //$('.alert').fadeOut(5000);
    
  }
}
