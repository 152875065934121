import { Component, OnInit } from '@angular/core';
import { Router }  from "@angular/router";

declare var $:JQueryStatic;
@Component({
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css']
})

export class ReservationsComponent implements OnInit {
  UserName: string;
  AppUserLoginname: string;
  AppUsername: string;
  constructor(private router : Router) { }

  ngOnInit() {
    // this.data.UserTierPoint.subscribe(message => this.UserTierPoint = message);
    this.UserName = sessionStorage.getItem('AppUserName');
    this.AppUserLoginname = sessionStorage.getItem('AppUserLoginname');
    this.AppUsername = sessionStorage.getItem('AppUserName');
  }

  logout()  {
    event.preventDefault();
     this.AppUserLoginname = sessionStorage.getItem('AppUserLoginname');
    sessionStorage.removeItem('ClientTierPoint');
    sessionStorage.removeItem('AppUserName');
    sessionStorage.removeItem('ClientTierName');
    sessionStorage.removeItem('AppClientName');
    sessionStorage.removeItem('CreditToNextTier');
    sessionStorage.removeItem('UserPoints');
    sessionStorage.removeItem('PlayerId');
    sessionStorage.removeItem('TargetId');
    sessionStorage.removeItem('AppUserLoginname');
    sessionStorage.removeItem('TargetAppUserNameId');
    
    console.log('logged Out');
    this.router.navigate(['/login']);
  }

}
