import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAppUserDetails } from './IAppUserDetails';
import { LoginServiceComponent } from './login.service';
import { delay } from 'rxjs/operators';

@Component({
  moduleId: module.id,
 templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginServiceComponent]
})
export class LoginComponent implements OnInit {
  active = false;
  failed = true;
  danger = false;
  warning = false;
  success = false;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  UserData: IAppUserDetails;
  loginError = '';

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private appService: LoginServiceComponent
      ) {
        // redirect to home if already logged in
         if (this.appService.currentUserValue) {
            this.router.navigate(['home']);
         }
      }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });


    // set return url to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    if (sessionStorage.getItem('AppUserName'))
    {
          // logged in so return true
         this.router.navigate(['/home']);
         return true;
      }

  }



  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.loginError = '';
    this.submitted = true;
    this.active = true;
    this.failed = false;
    $('.alert').removeAttr('style');
    this.danger = false;
    this.warning = false;
    this.success = false;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.active = false;
      this.failed = true;
      return;
    }


    console.log('Username:' + this.f.username.value);
    console.log('Password:' + this.f.password.value);

    this.loading = true;

    // this.appService.authenticateUser(this.f.username.value,this.f.password.value)
    //  .subscribe((svcData) => this.UserData = svcData);
    this.appService.authenticateUser(this.f.username.value, this.f.password.value)
    .then(svcData => {
        this.UserData = svcData;
        console.log('In Promise:' + this.UserData.Success);

        if (String(this.UserData.Success) === 'True') {
          console.log('Login successful!!');
          sessionStorage.setItem('ClientTierPoint', this.UserData['TierPoint']);
          sessionStorage.setItem('AppUserName', this.UserData['Name']);
          sessionStorage.setItem('ClientTierName', this.UserData['Tire']);
          sessionStorage.setItem('AppClientName', this.UserData['ClientName']);
          sessionStorage.setItem('CreditToNextTier', this.UserData['CreditToNextTier']);
          sessionStorage.setItem('UserPoints', String(this.UserData['UserPoints']));
          sessionStorage.setItem('PlayerId', this.UserData['PlayerId']);
          sessionStorage.setItem('TargetId', this.UserData['TargetId']);
          sessionStorage.setItem('SelfComp', this.UserData['SelfComp']);
          sessionStorage.setItem('AsOfDate', this.UserData['AsOfDate']);

          sessionStorage.setItem('AppUserLoginname', this.UserData['Username']);
          sessionStorage.setItem('AppUserLoginPassword', this.UserData['UserPassword']);
          sessionStorage.setItem('AppUserFirstName', this.UserData['FirstName']);
          sessionStorage.setItem('AppUserLastName', this.UserData['LastName']);
          sessionStorage.setItem('AppUserPhoneMain', this.UserData['PhoneMain']);
          console.log('Phone Mobile:' + this.UserData['PhoneMobile']);
          sessionStorage.setItem('AppUserPhoneMobile', this.UserData['PhoneMobile']);
          sessionStorage.setItem('AppUserEmailAddress', this.UserData['EmailAddress']);
          sessionStorage.setItem('AppUserAddress1', this.UserData['Address1']);
          sessionStorage.setItem('AppUserAddress2', this.UserData['Address2']);
          sessionStorage.setItem('AppUserCity', this.UserData['City']);
          sessionStorage.setItem('AppUserState', this.UserData['State']);
          sessionStorage.setItem('AppUserZipcode', this.UserData['Zipcode']);
          sessionStorage.setItem('AppUserCountry', this.UserData['Country']);
          
          this.router.navigate([this.returnUrl]);
        }

        if (String(this.UserData.Success) === 'False') {
          console.log('Login unsuccessful!!');
          this.active = false;
          this.failed = true;
          this.loginError = 'Invalid Rewards # and Password.';
          this.danger = true;
          this.warning = false;
          this.success = false;
          console.log('Login Error:' + this.loginError);
        }

      });

      $('.alert').fadeOut(5000);
  }
}
