import { Component } from '@angular/core';
import { Router }  from "@angular/router";


@Component({
 moduleId: module.id,
 templateUrl: './loggedin.component.html',
  styleUrls: ['./loggedin.component.css'],
})
export class loggedinComponent   {


}
