import { Injectable } from '@angular/core';
import { IOfferDetails } from './IOfferDetails';
import { IOfferResponse } from './IOfferReponse';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';

@Injectable()
export class OfferPromoServiceComponent {
  TargetId: string;
  apiURL: string = '';

  // tslint:disable-next-line: deprecation
  constructor(private http: Http) { }

  getOfferTypes(): Promise<string[]> {
      return this.http.get(environment.apiEndpoint + '/Dashboard/GetOfferTypes')
      .toPromise()
      .then(res => res.json())
      .catch(err => {
          return Promise.reject(err.json().error  || 'Server error');
    });

  }

  getOfferData(offerStartDate: string, offerEndDate: string, offerTypes: string): Promise<IOfferResponse> {
    // console.log(environment.apiEndpoint + '/Dashboard/GetOfferDetailsWithFilter/' + offerStartDate + '/' + offerEndDate + '/' + offerTypes);
    this.TargetId = sessionStorage.getItem('TargetId');

    if (offerTypes === '') {
      this.apiURL = environment.apiEndpoint + '/Dashboard/GetOfferDetailsWithFilter/';
      this.apiURL = this.apiURL + offerStartDate + '/' + offerEndDate + '/\'\'' + '/' + this.TargetId;
    } else {
      this.apiURL = environment.apiEndpoint + '/Dashboard/GetOfferDetailsWithFilter/';
      this.apiURL = this.apiURL + offerStartDate + '/' + offerEndDate + '/' + offerTypes + '/' + this.TargetId;
    }
    console.log('this.apiURL:' + this.apiURL);
    // tslint:disable-next-line: max-line-length
    return this.http.get(this.apiURL)
    .toPromise()
    .then(res => {
      console.log('Offer API Response:' + res.json());
      return res.json();
    })
    .catch(err => {
        return Promise.reject( console.log('Server error:' + err));
    });

  }
}
