import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leftinfo',
  templateUrl: './leftinfo.component.html',
  styleUrls: ['./leftinfo.component.css']
})
export class LeftinfoComponent implements OnInit {
  PlayerId: string;
  Tire: string;
  TierPoint: string;
  UserPoints: string;
  ClientName: string;
  SelfComp: string;
  AsOfDate: string;
  constructor() { }

  ngOnInit() {
    this.Tire = sessionStorage.getItem('ClientTierName');
    this.TierPoint = Number(sessionStorage.getItem('ClientTierPoint')).toLocaleString('en-GB');
    this.PlayerId = sessionStorage.getItem('PlayerId');
    this.UserPoints = Number(sessionStorage.getItem('UserPoints')).toLocaleString('en-GB');
    this.ClientName = sessionStorage.getItem('AppClientName');
    this.SelfComp = parseFloat(sessionStorage.getItem('SelfComp')).toString();
    this.AsOfDate = sessionStorage.getItem('AsOfDate');
    console.log(this.TierPoint + " left");
  }

}
