import { Injectable } from '@angular/core';
import { IOfferDetails } from './IOfferDetails';
import { IOfferImageDetails } from './IOfferImageDetails';
import { IPromotionDetails } from './IPromotionDetails';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { environment } from '../../../environments/environment';

@Injectable()
export class SliderServiceComponent {
  TargetId: string;
  // tslint:disable-next-line: deprecation
  constructor(private http: Http) { }

  getData(): Observable<IOfferDetails[]> {
    this.TargetId = sessionStorage.getItem('TargetId');
    // tslint:disable-next-line: max-line-length
    return this.http.get(environment.apiEndpoint + '/Dashboard/GetOfferDetails/' + this.TargetId)
      // tslint:disable-next-line: deprecation
      .pipe(map((response: Response) => response.json() as IOfferDetails[]));
  }

  getOfferImageData(): Observable<IOfferImageDetails[]> {
    return this.http.get( environment.apiEndpoint + '/Dashboard/GetOfferImagesByClient')
      .pipe(map((response: Response) => response.json() as IOfferImageDetails[]));
  }

  getPromotionData(): Observable<IPromotionDetails[]> {
    return this.http.get( environment.apiEndpoint + '/Dashboard/GetPromotionImagesByClient')
      .pipe(map((response: Response) => response.json() as IPromotionDetails[]));
  }

}
