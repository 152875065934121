import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  ClientName: string;
  TierName: string;
  UserName: string;  
  CreditToNextTier: string;
  
  constructor() { }
 

  ngOnInit() {
    // this.data.UserTierPoint.subscribe(message => this.UserTierPoint = message);
    this.UserName = sessionStorage.getItem('AppUserName');
    this.ClientName = sessionStorage.getItem('AppClientName');
    this.TierName = sessionStorage.getItem('ClientTierName');
    console.log(this.TierName);
    this.CreditToNextTier = sessionStorage.getItem('CreditToNextTier');
  }

}
