import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
declare var $:JQueryStatic;

@Component({
  selector: 'app-leftcount',
  templateUrl: './leftcount.component.html',
  styleUrls: ['./leftcount.component.css']
})
export class LeftcountComponent implements OnInit {
  TierPoint: string;
  ClientName: string;
  TierPointLength: number;
  ClassName: string;
  gradescore:number;
  constructor() {
    
   }

  ngOnInit() {
   // this.data.UserTierPoint.subscribe(message => this.UserTierPoint = message);
    this.ClientName = sessionStorage.getItem('AppClientName');
    this.TierPoint = Number(sessionStorage.getItem('ClientTierPoint')).toLocaleString('en-GB');
    this.TierPointLength = this.TierPoint.length;
    
    if(this.TierPointLength>4)
    {
      this.ClassName = "large TierPointNumber";
    
    }
    else
    {
      this.ClassName = "small TierPointNumber";
     // this.gradescore = 4.5;
    }

    switch (this.TierPointLength) {
      case 0:
          this.gradescore = 0;
          console.log(this.TierPointLength)
          break;
      case 1:
        this.gradescore = 1;
        console.log(this.TierPointLength)
          break;
      case 2:
        this.gradescore = 2;
        console.log(this.TierPointLength)
          break;
      case 3:
        this.gradescore = 4;
        console.log(this.TierPointLength)
          break;
      case 4:
        this.gradescore = 5;
        console.log(this.TierPointLength)
          break;
      case 5:
        this.gradescore = 6.5;
        console.log(this.TierPointLength)
          break;
      case 6:
        this.gradescore = 7.5;
        console.log(this.TierPointLength)
          break;
      default:
        this.gradescore = 8.5;
        console.log(this.TierPointLength)
          break;
  }

  }


  ngAfterViewInit() {      
      $(document).ready(function():void
      {    
        // tier score - home
        var $grades = $('.grade');       
        $grades.each(function() {
        var $grade = $(this);
        var $foreground = $grade.find('.grade__icon__foreground');
        var scorePercentage = $grade.data('grade-score') * 10;
        console.log(scorePercentage + "scorePercentage");
        var foregroundCircumference = 2 * Math.PI * parseInt($foreground.attr('r'));
        var foregroundDashOffset = foregroundCircumference - ((foregroundCircumference * scorePercentage) / 100);

        $foreground.css('stroke-dasharray', foregroundCircumference);
        $foreground.css('stroke-dashoffset', foregroundCircumference);

        setTimeout(function() {
          $grade.addClass('animate');
          $foreground.css('stroke-dashoffset', foregroundDashOffset);
          
        }, 200);
      });
    });
  }
}

