import { Component, OnInit } from '@angular/core';
//import { IOfferDetails } from './IOfferDetails';
import { IOfferImageDetails } from './IOfferImageDetails';
import { IPromotionDetails } from './IPromotionDetails';
import { SliderServiceComponent } from './slider-service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  providers: [SliderServiceComponent]
})
export class SliderComponent implements OnInit {
  //OfferData: IOfferDetails[];
  OfferImageData: IOfferImageDetails[];
  PromotionData: IPromotionDetails[];
  ClientName: string;
  constructor(private appService: SliderServiceComponent) { }

  ngOnInit() {
    //this.appService.getData()
      //.subscribe((svcData) => this.OfferData = svcData);

    this.appService.getOfferImageData()
      .subscribe((svcOfferData) => this.OfferImageData = svcOfferData);

    this.appService.getPromotionData()
      .subscribe((svcPromoData) => this.PromotionData = svcPromoData);

    this.ClientName = sessionStorage.getItem('AppClientName');
  }

}
