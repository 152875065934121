import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IForgotPasswordUserDetails } from './IForgotPasswordUserDetails';
import { ForgotpasswordServiceComponent } from './forgotpassword.service';
import { DatePipe } from '@angular/common';
import { delay } from 'rxjs/operators';
import { isUndefined } from 'util';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css'],
  providers: [ForgotpasswordServiceComponent]
})

export class ForgotpasswordComponent implements OnInit {
  danger = false;
  warning = false;
  success = false;
  signupForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  signupError = '';
  SignupData: IForgotPasswordUserDetails;
  DateOfBirth: string;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private appService: ForgotpasswordServiceComponent,
      private datePipe: DatePipe
      ) {}

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
          playerid: ['', Validators.required]
          //zip: ['', Validators.required]
      });

    // set return url to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'forgotpassworddetails';
  }


  // convenience getter for easy access to form fields
  get f() { return this.signupForm.controls; }

  onSubmit() {
    this.signupError = '';
    this.submitted = true;
    $('.alert').removeAttr('style');
    this.danger = false;
    this.warning = false;
    this.success = false;

    if(this.f.playerid.value == null || this.f.playerid.value == '')
    {
      this.signupError = 'Please enter PlayerId.';
      this.danger = true;
      this.warning = true;
      this.success = false;
      console.log('PlayerId cannot be blank.');
      return;
    } 

    if(isUndefined(this.DateOfBirth) || this.DateOfBirth == null || this.DateOfBirth == '')
    {
      this.signupError = 'Please enter Date Of Birth.';
      this.danger = true;
      this.warning = true;
      this.success = false;
      console.log('Date Of Birth cannot be blank.');
      return;
    } 

    /*if(this.f.zip.value == null || this.f.zip.value == '')
    {
      this.signupError = 'Please enter Zip.';
      this.danger = true;
      this.warning = true;
      this.success = false;
      console.log('Zip cannot be blank.');
      return;
    } */

    // stop here if form is invalid
    if (this.signupForm.invalid) {
      return;
    }

    console.log('PlayerId:' + this.f.playerid.value);
    console.log('DateOfBirth:' + this.DateOfBirth);
    //console.log('Zip:' + this.f.zip.value);

    this.loading = true;


    this.appService.authenticateSignupUser(this.f.playerid.value, this.DateOfBirth, null)
    .then(svcData => {
        this.SignupData = svcData;
        console.log('In Promise:' + this.SignupData.Success);

        
        if (String(this.SignupData.Success) === 'True') {
          console.log('Signup successful!!');

          if(this.SignupData['Username'] != null && this.SignupData['Username'] != '')
          {
            sessionStorage.setItem('TargetId', this.SignupData['TargetId']);
            sessionStorage.setItem('PlayerId', this.SignupData['PlayerId']);
            sessionStorage.setItem('AppSignupDateOfBirth', this.SignupData['Date_Of_Birth']);
            sessionStorage.setItem('AppSignupFirstName', this.SignupData['FirstName']);
            sessionStorage.setItem('AppSignupLastName', this.SignupData['LastName']);
            sessionStorage.setItem('AppSignupPhoneMain', this.SignupData['PhoneMain']);
            sessionStorage.setItem('AppSignupPhoneMobile', this.SignupData['PhoneMobile']);
            sessionStorage.setItem('AppSignupEmailAddress', this.SignupData['EmailAddress']);
            sessionStorage.setItem('AppSignupCity', this.SignupData['City']);
            sessionStorage.setItem('AppSignupState', this.SignupData['State']);
            sessionStorage.setItem('AppSignupZipcode', this.SignupData['Zipcode']);
            sessionStorage.setItem('AppSignupCountry', this.SignupData['Country']);
            sessionStorage.setItem('AppSignupLoginName', this.SignupData['Username']);
            //sessionStorage.setItem('AppSignupLoginPassword', this.SignupData['UserPassword']);
            sessionStorage.setItem('AppSignupSendNotification', this.SignupData['SendNotification']);
            sessionStorage.setItem('AppSignupSecurityQuestionId', this.SignupData['SecurityQuestionId']);
            sessionStorage.setItem('AppSignupSecurityAnswer', this.SignupData['SecurityAnswer']);

            this.router.navigate([this.returnUrl]);
          } else {
            this.signupError = 'Your account not exists. Please signup';
            this.danger = true;
            this.warning = true;
            this.success = false;
            console.log('Signup Error:' + this.signupError);
          }
        } else {
          //this.signupError = 'Invalid PlayerId, DOB or Zip entered...';
          this.signupError = 'Invalid PlayerId or DOB entered...';
          this.danger = true;
          this.warning = true;
          this.success = false;
          console.log('Signup Error:' + this.signupError);
        }
        
      });

      //$('.alert').fadeOut(5000);
  }

  GetDateRange(event: any) {
    this.DateOfBirth = this.datePipe.transform(new Date(event.target.value),"MM-dd-yyyy");
  }
}
