import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgotpasswordconfirm',
  templateUrl: './forgotpasswordconfirm.component.html',
  styleUrls: ['./forgotpasswordconfirm.component.css']
})
export class ForgotpasswordconfirmComponent implements OnInit {
  signupUserId: string;

  constructor() { }

  ngOnInit() {
    this.signupUserId = sessionStorage.getItem('AppSignupLoginName');
  }

}
