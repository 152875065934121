import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ForgotpassworddetailsServiceComponent } from './forgotpassworddetails.service';
import { ISaveForgotPasswordDetailsResponse } from './ISaveForgotPasswordDetailsResponse';

@Component({
  selector: 'app-forgotpassworddetails',
  templateUrl: './forgotpassworddetails.component.html',
  styleUrls: ['./forgotpassworddetails.component.css'],
  providers: [ForgotpassworddetailsServiceComponent]
})

export class ForgotpassworddetailsComponent implements OnInit {
  danger = false;
  warning = false;
  success = false;
  fpdetailsForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;  
  signupdetailsError = '';
  SignupLoginName: string;
  //SignupLoginPassword: string;
  //SignupLoginConfirmPassword: string;
  saveResponse: ISaveForgotPasswordDetailsResponse;
  //sessionLoginUserId: string;
  //sessionLoginPassword: string;
  loginUserId: string;
  loginPassword: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private appService: ForgotpassworddetailsServiceComponent
    ) {}

  ngOnInit() {

    this.fpdetailsForm = this.formBuilder.group({
      fpusername: ['', Validators.required],
      fppassword: ['', ''],
      fpconfirmpassword: ['', '']
    });

    // set return url to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'forgotpasswordconfirm';

    this.SignupLoginName = sessionStorage.getItem('AppSignupLoginName');
    //this.sessionLoginUserId = sessionStorage.getItem('AppSignupLoginName');

    //this.SignupLoginPassword = sessionStorage.getItem('AppSignupLoginPassword');
    //this.sessionLoginPassword = sessionStorage.getItem('AppSignupLoginPassword');
    //this.SignupLoginPassword = '';

    //this.SignupLoginConfirmPassword = sessionStorage.getItem('AppSignupLoginPassword');
    //this.SignupLoginConfirmPassword = '';

  }

  // convenience getter for easy access to form fields
  get f() { return this.fpdetailsForm.controls; }

  onSubmit() {
    this.submitted = true;
    $('.alert').removeAttr('style');
    this.danger = false;
    this.warning = false;
    this.success = false;
    this.loginUserId = '';
    this.loginPassword = '';

    console.log('username:' + this.f.fpusername.value);
    if (this.f.fpusername.value == null || this.f.fpusername.value == '') {
      this.signupdetailsError = 'Please enter Rewards #.';
      this.danger = true;
      this.warning = true;
      this.success = false;
      console.log('Username field cannot be blank.');
      return;
    } 

    console.log('password:' + this.f.fppassword.value);
    if(this.f.fppassword.value == null || this.f.fppassword.value == '')
    {
      this.signupdetailsError = 'Please enter Password.';
      this.danger = true;
      this.warning = true;
      this.success = false;
      return;
    } 

    if(this.f.fppassword.value.indexOf(' ') >= 0)
    {
      this.signupdetailsError = 'Blank space is not accepted as password.';
      this.danger = true;
      this.warning = true;
      this.success = false;
      return;
    } 

    console.log('confirmpassword:' + this.f.fpconfirmpassword.value);
    if(this.f.fpconfirmpassword.value == null || this.f.fpconfirmpassword.value == '')
    { 
      this.signupdetailsError = 'Please enter Confirm Password.';
      this.danger = true;
      this.warning = true;
      this.success = false;
      return;
    }

    if(this.f.fppassword.value != this.f.fpconfirmpassword.value)
    { 
      this.signupdetailsError = 'Password and Confirm Password are mismatched.';
      this.danger = true;
      this.warning = true;
      this.success = false;
      return;
    }

    //console.log('sessionLoginUserId:' + this.sessionLoginUserId);
    //console.log('username:' + this.f.username.value);
    //console.log('sessionLoginPassword:' + this.sessionLoginPassword);
    //console.log('password:' + this.f.password.value);
    //if((this.sessionLoginUserId != this.f.username.value) || (this.sessionLoginPassword != this.f.password.value))
    if(this.f.fpusername.value != '' && this.f.fppassword.value != '')
    {
      this.loginUserId = this.f.fpusername.value;
      this.loginPassword = this.f.fppassword.value;
    } else {
      this.loginUserId = '';
      this.loginPassword = '';
    }
    
    
    // stop here if form is invalid
    if (this.fpdetailsForm.invalid) {
      return;
    }

    console.log('Form is valid..');

    this.loading = true;

    this.appService.saveSignupAccountMaster(this.loginUserId, this.loginPassword, 0, '')
      .then(svcData => {
        this.saveResponse = svcData;
        console.log('In Promise:' + this.saveResponse.Success);

        if (this.saveResponse.Success) {
          sessionStorage.setItem('AppSignupLoginName', this.f.fpusername.value);
          //sessionStorage.setItem('AppSignupLoginPassword', this.f.fppassword.value);
          console.log('Password saved successfully.');

          this.router.navigate([this.returnUrl]);
        } else {
          this.signupdetailsError = 'Password could not be saved.';
          this.danger = true;
          this.warning = true;
          this.success = false;
          console.log('Failure Response:' + this.saveResponse.msg);
        }
      });

      //$('.alert').fadeOut(5000);
    
  }
}
