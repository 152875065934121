import { Component, OnInit } from '@angular/core';
import { Router }  from "@angular/router";
declare var $:JQueryStatic;
@Component({
  selector: 'app-profileinfo',
  templateUrl: './profileinfo.component.html',
  styleUrls: ['./profileinfo.component.css']
})
export class ProfileinfoComponent implements OnInit {
  UserName: string;
  constructor(private router : Router) { }

  ngOnInit() {
    // this.data.UserTierPoint.subscribe(message => this.UserTierPoint = message);
    this.UserName = sessionStorage.getItem('AppUserName');
  }

  logout()  {
    event.preventDefault();
    sessionStorage.removeItem('ClientTierPoint');
    sessionStorage.removeItem('AppUserName');
    sessionStorage.removeItem('ClientTierName');
    sessionStorage.removeItem('AppClientName');
    sessionStorage.removeItem('CreditToNextTier');
    sessionStorage.removeItem('UserPoints');
    sessionStorage.removeItem('PlayerId');
    sessionStorage.removeItem('TargetId');
    console.log('logged Out');
    this.router.navigate(['/login']);
  }

}
